import { getField, updateField } from 'vuex-map-fields'
import { db } from '@/services/firebase'

export const state = () => ({})

export const getters = {
  getField
}

export const actions = {
  async getConversation({ commit }, id) {
    const conversation = await db.collection('conversations').doc(id).get()
    console.log('conversation', conversation.data())
    // commit('updateField', { path: 'conversation', value: conversation.data() })
  }
}

export const mutations = {
  updateField
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
