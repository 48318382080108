<template lang="pug">
v-bottom-navigation(
  :value="1"
  color="primary"
  fixed
  app
)
  v-btn(
    v-if="route.type === user.type || !route.type"
    v-for="(route, i) in routes"
    :key="i"
    text
    :to="route.path"
  )
    v-icon(:color="routePath === route.path ? 'primary' : 'white'") {{ route.icon }}
    span(:class="routePath === route.path ? 'primary--text' : 'white--text'") {{ route.title }}
</template>

<script>
export default {
  data() {
    return {
      routes: [
        {
          title: 'Home',
          icon: 'mdi-home',
          path: '/home'
        },
        {
          title: 'Alunos',
          icon: 'mdi-account-multiple',
          path: '/users',
          type: 'personal'
        },
        {
          title: 'Rotinas',
          icon: 'mdi-list-box',
          path: '/routines'
        },
        {
          title: 'Treinos',
          icon: 'mdi-dumbbell',
          path: '/workouts',
          type: 'personal'
        },
        {
          title: 'Exercícios',
          icon: 'mdi-weight-lifter',
          path: '/exercises',
          type: 'client'
        },
        // {
        //   title: 'Estatísticas',
        //   icon: 'mdi-chart-bar',
        //   path: '/statistics',
        //   type: 'client'
        // },
        {
          title: 'Mais',
          icon: 'mdi-dots-horizontal',
          path: '/more'
        }
      ]
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/me']
    },
    routePath() {
      return this.$route.path
    }
  }
}
</script>
