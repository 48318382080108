import { getField, updateField } from 'vuex-map-fields'
// import { getAllExercises } from '@/utils/exercises'
import api from '@/services/api'

export const state = () => ({
  categories: null,
  equipments: null,
  personalExercises: null
})

export const getters = {
  getField,
  categories(state) {
    return state.categories
  },
  equipments(state) {
    return state.equipments
  },
  personalExercises(state) {
    return state.personalExercises
  }
}

const getAllExercises = async () => {
  const { data } = await api.get('/exercises')
  return data
}

export const actions = {
  async listPaginated(
    { commit },
    { search, last, limit, where, orderBy } = {}
  ) {
    const { data } = await api.get('/exercises/paginated', {
      params: {
        search,
        last,
        limit,
        where,
        orderBy
      }
    })
    return data
  },

  async get(_, id) {
    const { data } = await api.get(`/exercises/${id}`)
    return data
  },

  async list() {
    return getAllExercises()
  },

  async create(_, payload) {
    const { data } = await api.post('/exercises', payload)
    return data
  },
  async update(_, payload) {
    const { data } = await api.put(`/exercises/${payload.id}`, payload)
    return data
  },
  async delete(_, id) {
    await api.delete(`/exercises/${id}`)
  }
  // async getAllCategories({ commit }) {
  //   let data = await getAllExercises()
  //   data = [
  //     'Meus exercícios',
  //     ...new Set(data.map((exercise) => exercise.target))
  //   ]
  //   commit('updateField', { path: 'categories', value: data })
  // },
  // async getAllEquipments({ commit }) {
  //   let data = await getAllExercises()
  //   data = [...new Set(data.map((exercise) => exercise.equipment))]
  //   commit('updateField', { path: 'equipments', value: data })
  // },
  // async getExercisesByCategory({ commit, state }, category) {
  //   if (category === 'Meus exercícios') {
  //     return state.personalExercises
  //   }

  //   const data = await getAllExercises()
  //   return data.filter((exercise) => exercise.target === category)
  // },
  // async getExercisesByEquipment({ commit }, equipment) {
  //   const data = await getAllExercises()
  //   return data.filter((exercise) => exercise.equipment === equipment)
  // },
  // async getExercisesByCategoryAndEquipment(
  //   { commit },
  //   { category, equipment }
  // ) {
  //   const data = await getAllExercises()
  //   return data.filter(
  //     (exercise) =>
  //       exercise.target === category && exercise.equipment === equipment
  //   )
  // },
  // async getExerciseById({ commit }, id) {
  //   const data = await getAllExercises()
  //   return data.find((exercise) => exercise.id === id)
  // },
  // async getPersonalExercises({ commit }, personalId) {
  //   let { data } = await api.get(`/exercises/user/${personalId}`)
  //   // faço esse map só pra padronizar as propriedades dos exercícios (ainda falta algumas propriedades como bodyPart)
  //   data = data.map((exercise) => {
  //     return {
  //       id: exercise.id,
  //       name: exercise.name,
  //       target: exercise.category,
  //       equipment: exercise.equipment,
  //       mediaUrl: exercise.mediaUrl
  //     }
  //   })
  //   commit('updateField', { path: 'personalExercises', value: data })
  // },
  // async getCustomExercise(_, exerciseId) {
  //   const { data } = await api.get(`/exercise/custom/${exerciseId}`)
  //   console.log('getCustomExercise', data)
  //   return {
  //     id: data.id,
  //     name: data.name,
  //     target: data.category,
  //     equipment: data.equipment,
  //     mediaUrl: data.mediaUrl
  //   }
  // }
}

export const mutations = {
  updateField
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
