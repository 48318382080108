import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'

import { getField, updateField } from 'vuex-map-fields'

import VuexPersistence from 'vuex-persist'

const vuexPersist = new VuexPersistence({
  key: `workout_vuex`,
  storage: window.localStorage,
  // escolher quais módulos vão persistir por exemplo "auth" para autenticação
  modules: ['auth', 'user', 'workouts', 'lastActivities', 'createWorkout']
})

Vue.use(Vuex)

export const storeOptions = {
  strict: true,
  getters: {
    getField
  },
  mutations: {
    updateField
  },
  modules: modules,
  plugins: [vuexPersist.plugin]
}

export default new Vuex.Store(storeOptions)
