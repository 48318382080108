import { getField, updateField } from 'vuex-map-fields'

export const state = () => ({
  snackbar: {
    show: false,
    color: 'success',
    text: 'Success'
  }
})

export const getters = {
  getField,
  snackbar(state) {
    return state.snackbar
  }
}

export const actions = {
  success({ commit }, payload) {
    commit('updateField', {
      path: 'snackbar',
      value: {
        show: true,
        color: 'success',
        text: payload
      }
    })
  },
  error({ commit }, payload) {
    commit('updateField', {
      path: 'snackbar',
      value: {
        show: true,
        color: 'error',
        text: payload
      }
    })
  }
}

export const mutations = {
  updateField
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
