<template lang="pug">
v-snackbar.mr-4.mt-4(
  v-model="snackbar.show"
  :color="snackbar.color"
  top
  right
  :timeout="2000"
  height="10"
)
  .text-none.text-subtitle-1.text-center {{ snackbar.text }}
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('snackbar', ['snackbar'])
  }
}
</script>
