import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth } from '@/services/supabase'
import store from '@/store/'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    // component: () => import('@/pages/LandingPage.vue')
    redirect: '/home',
    meta: {
      layout: 'no-sidebar'
    }
  },
  {
    path: '/home',
    component: () => import('@/pages/Home.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    component: () => import('@/pages/access/Login.vue'),
    meta: {
      layout: 'no-sidebar'
    }
    // beforeEnter(to, from, next) {
    //   if (auth.currentUser) {
    //     next('/home')
    //   } else {
    //     next()
    //   }
    // }
  },
  {
    path: '/register',
    component: () => import('@/pages/access/Register.vue'),
    meta: {
      layout: 'no-sidebar'
    }
    // beforeEnter(to, from, next) {
    //   if (auth.currentUser) {
    //     next('/home')
    //   } else {
    //     next()
    //   }
    // }
  },
  {
    path: '/convite',
    component: () => import('@/pages/Invite.vue'),
    meta: {
      layout: 'no-sidebar'
    }
  },
  {
    path: '/forgot-password',
    component: () => import('@/pages/access/ForgotPassword.vue'),
    meta: {
      layout: 'no-sidebar'
    }
  },
  {
    path: '/reset-password',
    component: () => import('@/pages/access/ResetPassword.vue'),
    meta: {
      layout: 'no-sidebar'
    }
  },
  {
    path: '/users',
    component: () => import('@/pages/Users.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/users/:id',
    component: () => import('@/pages/User.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile',
    component: () => import('@/pages/Profile.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile/informations',
    component: () => import('@/pages/profile/Informations.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile/edit/:data',
    component: () => import('@/pages/profile/EditInformation.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/exercises',
    component: () => import('@/pages/exercises/Exercises.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/exercises/api',
    component: () => import('@/pages/ExercisesApi.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/exercises/:id',
    component: () => import('@/pages/Exercise.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/workouts/create',
    component: () => import('@/pages/workout/WorkoutManage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/workouts/create/:id',
    component: () => import('@/pages/workout/WorkoutManage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/workouts',
    component: () => import('@/pages/workout/Workouts.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/routines',
    component: () => import('@/pages/routines/Routines.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/routine/:id',
    component: () => import('@/pages/routines/Routine.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/routines/create',
    component: () => import('@/pages/routines/CreateRoutine.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user/routine/',
    component: () => import('@/pages/routines/UserRoutine.vue')
  },
  // {
  //   path: '/user/routine/workout/',
  //   component: () => import('@/pages/workout/ClientWorkout.vue'),
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  {
    path: '/client/routine/:id',
    component: () => import('@/components/home/ClientRoutine.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/client/workout/:id',
    component: () => import('@/components/home/ClientWorkoutManage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/workout/start',
    component: () => import('@/components/home/ClientStartWorkout.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/workout/completed',
    component: () => import('@/components/home/StatisticsFinishedWorkout.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/statistics/',
    component: () => import('@/pages/Statistics.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/more',
    component: () => import('@/pages/More.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/chat',
    component: () => import('@/pages/Chat.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/chat/:id',
    component: () => import('@/pages/Chat.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/solicitations',
    component: () => import('@/pages/Solicitations.vue'),
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  store?.dispatch('loading/setLoading', true)
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  const currentUser = !!Object.keys(store.getters['user/me']).length
  if (requiresAuth && !currentUser) {
    next('/login')
  } else {
    next()
  }
})

router.afterEach(() => {
  store?.dispatch('loading/setLoading', false)
})

export default router
