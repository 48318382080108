const { createClient } = require('@supabase/supabase-js')
const supabaseUrl = 'https://gfcaihniqcgmmjikkqap.supabase.co'
const supabaseKey =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImdmY2FpaG5pcWNnbW1qaWtrcWFwIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTc5NTY2NjcsImV4cCI6MjAzMzUzMjY2N30.pbr8BjgGm9fcjnE-XK0D_Y5iTu9IXr7itV_hw3Stkj0'

const supabase = createClient(supabaseUrl, supabaseKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true
  }
})

module.exports = supabase
