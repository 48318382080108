import { getField, updateField } from 'vuex-map-fields'
import api from '@/services/api'

const initialState = () => ({
  workout: {
    name: '',
    description: '',
    feedback: '',
    mediaUrl: '',
    day: '',
    rpe: false,
    exercises: []
  },
  defaultWorkout: null,
  workoutInProgress: false,
  rest: '0s',
  restResetCounter: 0,
  lastFinishedWorkout: null
})

export const state = () => initialState()

export const getters = {
  getField,
  workout(state) {
    return state.workout
  },
  totalVolume(state) {
    return state.workout.exercises.reduce((total, exercise) => {
      return (
        total +
        exercise.series
          .filter((serie) => serie.done)
          .reduce(
            (sum, serie) => sum + Number(serie.weigth) * Number(serie.reps),
            0
          )
      )
    }, 0)
  },
  totalSeries(state) {
    return state.workout.exercises.reduce((total, exercise) => {
      return total + exercise.series.filter((serie) => serie.done).length
    }, 0)
  },
  allTotalVolume(state) {
    return state.workout.exercises.reduce((total, exercise) => {
      return (
        total +
        exercise.series.reduce(
          (sum, serie) => sum + Number(serie.weigth) * Number(serie.reps),
          0
        )
      )
    }, 0)
  },
  allTotalSeries(state) {
    return state.workout.exercises.reduce((total, exercise) => {
      return total + exercise.series.length
    }, 0)
  }
}

export const actions = {
  async get(_, id) {
    const { data } = await api.get(`/workouts/${id}`)
    return data
  },
  async list(_, { where = {} }) {
    const { data } = await api.get('/workouts', {
      params: {
        where: JSON.stringify({
          ...where
        })
      }
    })
    return data
  },
  async create(_, payload) {
    const { data } = await api.post('/workouts', payload)
    return data
  },
  async update(_, payload) {
    const { data } = await api.put(`/workouts/${payload.id}`, payload)
    return data
  },
  async delete(_, id) {
    await api.delete(`/workouts/${id}`)
  },
  async duplicate(_, workout) {
    const { data } = await api.post(`/workouts`, {
      ...workout,
      name: `${workout.name} - Cópia`
    })
    return data
  },
  async setWorkout({ commit }, payload) {
    console.log('setWorkoutPayload', payload)
    commit('setWorkout', payload)
  },
  async setDefaultWorkout({ commit }, payload) {
    commit('setDefaultWorkout', payload)
  },
  async postWorkout({ commit }, payload) {
    const { data } = await api.post(
      `/finished/workout/${payload.userId}`,
      payload
    )
    return data
  },

  async getWorkoutByClient({ commit }, id) {
    const { data } = await api.get(`/finished/workouts/${id}`)
    return data
  },

  async getWorkoutsByClient({ commit }, id) {
    const { data } = await api.get('/workouts/client', {
      params: {
        id
      }
    })
    return data
  },

  updateSerie({ commit }, { indexExercise, indexSerie, value }) {
    commit('updateSerie', {
      indexExercise,
      indexSerie,
      value
    })
  },

  changeSerieDone({ commit }, { indexExercise, indexSerie, value }) {
    commit('changeSerieDone', {
      indexExercise,
      indexSerie,
      value
    })
  },

  updateWorkout({ commit }, payload) {
    console.log('updateWorkoutPayload', payload)
    commit('setWorkout', payload)
  },

  clearWorkout({ commit }) {
    commit('setWorkout', initialState().workout)
  }
}

export const mutations = {
  updateField,
  setWorkout(state, payload) {
    state.workout = payload
  },
  updateSerie(state, { indexExercise, indexSerie, value }) {
    console.log('updateSerie', value)
    // Utilizar splice para garantir reatividade
    state.workout.exercises[indexExercise].series.splice(indexSerie, 1, value)
  },
  changeSerieDone(state, { indexExercise, indexSerie, value }) {
    console.log('changeSerieDone', value)

    const serieVolume = value.weigth * value.reps

    if (value.done) {
      state.workout.exercises[indexExercise].currentSeriesVolume = serieVolume
      state.rest = value.rest
      state.restResetCounter++
      console.log(' state.rest', state.rest)
    } else {
      state.workout.exercises[indexExercise].currentSeriesVolume = 0
      // state.rest = '0s'
    }
    state.workout.exercises[indexExercise].series.splice(indexSerie, 1, value)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
