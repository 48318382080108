import { getField, updateField } from 'vuex-map-fields'
import api from '@/services/api'

export const state = () => ({})

export const getters = {
  getField
}

export const actions = {
  async get(_, { id, token }) {
    const { data } = await api.get('/invites', {
      params: {
        id,
        token
      }
    })
    return data
  },
  async list(_, { where = {} }) {
    const { data } = await api.get('/invites/list', {
      params: {
        where: JSON.stringify({
          ...where
        })
      }
    })
    return data
  },
  async create(_, payload) {
    const { data } = await api.post('/invites', payload)
    return data
  },
  async bulkCreate(_, payload) {
    const { data } = await api.post('/invites/bulk', payload)
    return data
  },
  async update(_, payload) {
    const { data } = await api.put(`/invites/${payload.id}`, {
      ...payload
    })
    return data
  },
  async delete(_, id) {
    await api.delete(`/invites/${id}`)
  }
}

export const mutations = {
  updateField
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
