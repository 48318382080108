import { getField, updateField } from 'vuex-map-fields'
import api from '@/services/api'
import { orderBy } from 'firebase/firestore'

export const state = () => ({})

export const getters = {
  getField
}

export const actions = {
  async get(_, id) {
    const { data } = await api.get(`/muscles/${id}`)
    return data
  },
  async list(_) {
    const { data } = await api.get('/muscles', {
      params: {
        orderBy: 'desc'
      }
    })
    return data
  },
  async create(_, payload) {
    const { data } = await api.post('/muscles', payload)
    return data
  },
  async update(_, payload) {
    const { data } = await api.put(`/muscles/${payload.id}`, payload)
    return data
  },
  async delete(_, id) {
    await api.delete(`/muscles/${id}`)
  }
}

export const mutations = {
  updateField
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
