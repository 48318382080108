import { getField, updateField } from 'vuex-map-fields'
import { auth } from '@/services/supabase'
import api from '@/services/api'

export const state = () => ({})

export const getters = {
  getField
}

export const actions = {
  async sendOtp({ commit }, { email }) {
    try {
      const { data } = await auth.signInWithOtp({
        email,
        options: {
          shouldCreateUser: true
        }
      })
      return data
    } catch (err) {
      console.error('ERRO NO SENDOTP', err)
      return err?.response?.data
    }
  },
  async verifyOtp({ commit }, { email, otp }) {
    try {
      const { data } = await auth.verifyOtp({
        email,
        token: otp,
        type: 'email'
      })
      return data
    } catch (err) {
      console.error('ERRO NO VERIFYOTP', err)
      return err?.response?.data
    }
  },
  async update({ commit }, { email, password }) {
    try {
      const { data } = await auth.updateUser({
        email,
        password
      })
      return data
    } catch (err) {
      console.error('ERRO NO UPDATE', err)
      return err?.response?.data
    }
  },
  async register({ commit }, { email, password }) {
    try {
      const { data } = await auth.signUp({
        email,
        password
      })
      return data
    } catch (err) {
      console.error('ERRO NO REGISTER', err)
      return err?.response?.data
    }
  },
  async registerInvite({ commit }, { email, password }) {
    try {
      const { data } = await api.post('/auth/register/invite', {
        email,
        password
      })
      return data
    } catch (err) {
      console.error('ERRO NO REGISTER', err)
      throw err?.response?.data
    }
  },
  async login({ commit }, { email, password }) {
    try {
      const { data, error } = await auth.signInWithPassword({
        email,
        password
      })

      if (error) {
        throw error
      }

      const { data: userData } = await api.get('/users', {
        params: {
          where: JSON.stringify({
            email
          })
        }
      })

      commit(
        'updateField',
        { path: 'user.me', value: userData[0] },
        { root: true }
      )
      commit(
        'updateField',
        { path: 'user.session', value: data },
        { root: true }
      )
      return { data }
    } catch (error) {
      console.error('ERRO NO LOGIN', error)
      throw { error }
    }
  },
  async logout({ commit }) {
    await auth.signOut()
    commit('updateField', { path: 'user.me', value: null }, { root: true })
    commit('updateField', { path: 'user.session', value: null }, { root: true })
    commit('updateField', { path: 'user', value: null })
  },
  async forgotPassword({ commit }, email) {
    try {
      const { data } = await api.post('/auth/forgot-password', email)
      console.log('FORGOT PASSWORD', data)
      return data
    } catch (err) {
      console.error('ERRO NO FORGOT PASSWORD', err)
      return err?.response?.data
    }
  },
  async resetPassword({ commit }, { password, token }) {
    console.log('RESET PASSWORD', password, token)
    try {
      const { data, error } = await auth.updateUser({
        password,
        token
      })
      if (error) {
        console.error('ERRO NO RESET PASSWORD', error)
        return error
      }

      return data
    } catch (err) {
      console.error('ERRO NO RESET PASSWORD', err)
      return err?.response?.data
    }

    // try {
    //   const { data } = await api.post('/auth/reset-password', payload)
    //   console.log('RESET PASSWORD', data)
    //   return data
    // } catch (err) {
    //   console.error('ERRO NO RESET PASSWORD', err)
    //   return err?.response?.data
    // }
  },
  async updatePassword({ commit }, payload) {
    // const { user } = await signInWithEmailAndPassword(
    //   auth,
    //   payload.email,
    //   payload.password
    // )
    // try {
    //   await updatePassword(user, payload.newPassword)
    // } catch (err) {
    //   console.error(err)
    // }
  },
  async setUser({ commit }, payload) {
    delete payload?.temporaryPassword
    delete payload?.password
    delete payload?.repeatPassword
    commit('updateField', { path: 'user.me', value: payload }, { root: true })
  },
  // async getUser({ commit }, id) {
  //   const { data } = await api.get(`/users/${id}`)
  //   commit('updateField', { path: 'user.me', value: data }, { root: true })
  // },
  async changePassword({ commit }, payload) {
    // try {
    //   await signInWithEmailAndPassword(
    //     auth,
    //     auth.currentUser.email,
    //     payload.currentPassword
    //   )
    // } catch (err) {
    //   console.error(err)
    //   return err.code
    // }
    // try {
    //   await updatePassword(auth.currentUser, payload.newPassword)
    //   return 'success'
    // } catch (err) {
    //   console.error(err)
    //   return err.code
    // }
  },
  async checkIfEmailExists(_, email) {
    try {
      const { data } = await api.get('/users', {
        params: {
          where: JSON.stringify({
            email
          })
        }
      })

      if (data.length) {
        return true
      } else {
        return false
      }
    } catch (err) {
      console.error(err)
      return err.code
    }
  }
}

export const mutations = {
  updateField
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
