import Vue from 'vue'
import App from './App.vue'
import axios from '@/services/api'
import vuetify from './plugins/vuetify'
import router from '@/routes/router'
import store from '@/store/'
import VueMask from 'v-mask'
import '@/plugins/styles'
import { auth } from '@/services/supabase'
import moment from 'moment'
import 'moment/locale/pt-br'

Vue.component('FyHeader', () => import('@/components/Header.vue'))
Vue.component('FyDialog', () => import('@/components/Dialog.vue'))
Vue.component('FyLoading', () => import('@/components/loadingCircular.vue'))
Vue.component('FyUploader', () => import('@/components/Uploader.vue'))
Vue.component('FyGridList', () => import('@/components/inputs/GridList.vue'))
Vue.config.productionTip = false
Vue.use(VueMask)
Vue.prototype.$api = axios
Vue.prototype.$moment = moment

let app = ''

auth.onAuthStateChange((event, session) => {
  // console.log('event', event)
  // console.log('session', session)
  if (!app) {
    app = new Vue({
      vuetify,
      router,
      store,
      render: (h) => h(App)
    }).$mount('#app')
  }
})
