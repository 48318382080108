<template lang="pug">
fy-dialog(
  v-model="visible"
  :title="title"
  :rButtonLoading="loading"
  :rButtonTitle="yes"
  :lButtonTitle="no"
  @rButtonClick="callResolve(true)"
  @lButtonClick="visible = false"
  @closeDialog="visible = false"
)
  template(#content)
    v-row.px-4
      v-col(cols=12)
        | {{ text }}
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  data() {
    return {}
  },
  computed: {
    ...mapFields('app', [
      'confirmationDialog.visible',
      'confirmationDialog.loading',
      'confirmationDialog.title',
      'confirmationDialog.text',
      'confirmationDialog.yes',
      'confirmationDialog.no',
      'confirmationDialog.yesColor',
      'confirmationDialog.noColor',
      'confirmationDialog.resolve',
      'confirmationDialog.reject'
    ])
  },
  methods: {
    closeDialog() {
      this.$store.dispatch('app/closeConfirmationDialog')
    },
    callResolve(boolean) {
      if (this.resolve !== null) {
        this.resolve(boolean)
      }
      this.$nextTick(() => {
        this.closeDialog()
      })
    }
  }
}
</script>
