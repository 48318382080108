<template lang="pug">
fy-dialog(
  v-model="showWhatsNewDialog"
  transition="dialog-bottom-transition"
  max-width="500px"
  title="O que há de novo?"
  scrollable
  :hideButtons="true"
  @closeDialog="$emit('closeDialog')"
)
  template(#content)
    v-container
      v-row(justify="center")
        v-col.mb-4(
          v-for="(feature, index) in features"
          cols=12
          :key="index"
        )
          v-row(
            no-gutters
            justify="space-between"
            align="center"
          )
            v-col(cols="auto")
              v-row(no-gutters align="center")
                v-col(cols=12)
                  .text-subtitle-1.font-weight-bold {{ feature?.title }}
            v-col(cols="auto")
              .text-body-1 {{ formatDate(feature?.createdAt) }}
          v-row.mt-2(no-gutters align="center")
            v-col(
              v-for="(i, index) in feature.features"
              cols=12
              :key="index"
            )
              li.text-body-2.grey--text {{ i }}
</template>

<script>
import { formatDate } from '@/utils/format'
import moment from 'moment'

export default {
  model: {
    prop: 'showWhatsNewDialog',
    event: 'closeDialog'
  },
  props: {
    showWhatsNewDialog: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      features: null
    }
  },
  watch: {
    showWhatsNewDialog(val) {
      if (!val) {
        this.$emit('closeDialog')
      }
    }
  },
  created() {
    // this.getFeatures()
  },
  methods: {
    formatDate(d) {
      let date = formatDate(d)
      return moment(date, 'DD/MM/YYYY HH:mm').format('LL')
    },
    async getFeatures() {
      try {
        this.features = await this.$store.dispatch('news/list')
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>
