import { getField, updateField } from 'vuex-map-fields'

export const state = () => ({
  loading: false
})

export const getters = {
  getField
}

export const actions = {
  setLoading({ commit }, payload) {
    commit('updateField', { path: 'loading', value: payload })
  }
}

export const mutations = {
  updateField
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
