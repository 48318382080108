import { getField, updateField } from 'vuex-map-fields'
import api from '@/services/api'

export const state = () => ({})

export const getters = {
  getField
}

export const actions = {
  async get(_, id) {
    const { data } = await api.get(`/tickets/${id}`)
    return data
  },
  async list(_, { where = {} }) {
    const { data } = await api.get('/tickets', {
      params: {
        where: JSON.stringify({
          ...where
        })
      }
    })
    return data
  },
  async create(_, payload) {
    const { data } = await api.post('/tickets', payload)
    return data
  },
  async update(_, payload) {
    const { data } = await api.put(`/tickets/${payload.id}`, payload)
    return data
  },
  async delete(_, id) {
    await api.delete(`/tickets/${id}`)
  }
}

export const mutations = {
  updateField
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
