import { getField, updateField } from 'vuex-map-fields'

export const state = () => ({
  confirmationDialog: {
    visible: false,
    loading: false,
    title: 'Atenção',
    text: '',
    yes: 'Sim',
    no: 'Cancelar',
    yesColor: 'primary',
    noColor: '',
    resolve: null,
    reject: null
  }
})

export const getters = {
  getField
}

export const actions = {
  async askConfirmation(
    { commit },
    { title, text, yes, no, yesColor, noColor }
  ) {
    return new Promise((resolve, reject) => {
      commit('setConfirmationDialogProps', {
        title,
        text,
        yes,
        no,
        yesColor,
        noColor,
        resolve,
        reject
      })
    })
  },
  async closeConfirmationDialog({ commit }) {
    commit('setConfirmationDialogProps', {
      visible: false,
      loading: false,
      title: 'Atenção',
      text: '',
      yes: 'Sim',
      no: 'Cancelar',
      yesColor: 'primary',
      noColor: '',
      resolve: null,
      reject: null
    })
  }
}

export const mutations = {
  updateField,
  setConfirmationDialogProps(
    state,
    {
      title = 'Atenção',
      text = '',
      visible = true,
      loading = false,
      yes = 'Sim',
      no = 'Cancelar',
      yesColor = 'primary',
      noColor = '',
      resolve = null,
      reject = null
    }
  ) {
    state.confirmationDialog.title = title
    state.confirmationDialog.text = text
    state.confirmationDialog.visible = visible
    state.confirmationDialog.loading = loading
    state.confirmationDialog.yes = yes
    state.confirmationDialog.no = no
    state.confirmationDialog.yesColor = yesColor
    state.confirmationDialog.noColor = noColor
    state.confirmationDialog.resolve = resolve
    state.confirmationDialog.reject = reject
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
