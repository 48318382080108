<template lang="pug">
v-navigation-drawer.overflow-hidden(
  style="background-color: #252525"
  app
  floating
  overlay-color="transparent"
)
  template(#prepend)
    v-container
      v-row
        v-col(cols=12)
          .text-h6.font-weight-bold Fysique
  template(#default)
    v-list(
      nav
      dense
      style="border-radius: 0 8px 8px 0"
    )
      v-list-item-group
        div(v-for="(menuItem, i) in menuItems" :key="i")
          v-list-item.mb-1(
            v-if="menuItem.type === user.type || !menuItem.type"
            :to="menuItem.path"
            :class="menuItem.path === $route.path ? 'primary secondary--text' : ''"
          )
            v-list-item-title
              v-icon.mr-4 {{ menuItem.icon }}
              | {{ menuItem.title }}
          .ml-2.mb-2.text-body-2.text--secondary(
            v-if="menuItem.type === 'text'"
          ) {{ menuItem.title.toUpperCase() }}
          v-divider.my-4(v-if="menuItem.type === 'divider'")
  template(#append)
    v-row.mb-6(
      no-gutters
      justify="center"
      dense
    )
      v-col
        v-btn(
          elevation="0"
          text
          block
          @click="$emit('openFeedbackDialog')"
        )
          .text-body-2 Feedback
      v-divider.my-2(vertical)
      v-col
        v-btn(
          elevation="0"
          text
          block
          @click="$emit('openHelpDialog')"
        )
          .text-body-2 Ajuda
      //- v-col.text-center(cols=12)
      //-   v-badge(
      //-     color="white"
      //-     dot
      //-     top
      //-     right
      //-     overlap
      //-   )
      //-     v-btn(
      //-       color="primary"
      //-       elevation="0"
      //-       text
      //-       block
      //-       @click="$emit('openWhatsNewDialog')"
      //-     )
      //-       .text-body-2 O que há de novo?

    v-divider
    v-row.my-2(
      no-gutters
      justify="space-between"
      align="center"
    )
      v-col.ml-3(
        cols="auto"
        style="cursor: pointer"
        @click="goTo('/profile')"
      )
        v-avatar(size="32")
          v-img(:src="user.mediaUrl")
        span.ml-2.text-subtitle-2 {{ user.name }}
      v-col(cols="auto")
        v-btn(
          color="error"
          icon
          small
          elevation="0"
          @click="logout()"
        )
          v-icon(color="red" small) mdi-logout
  FeedbackDialog(
    v-model="showFeedbackDialog"
    @closeDialog="showFeedbackDialog = false"
  )
</template>

<script>
import FeedbackDialog from '@/components/dialogs/Feedback.vue'

export default {
  components: {
    FeedbackDialog
  },
  model: {
    prop: 'drawer',
    event: 'close'
  },
  props: {
    drawer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showFeedbackDialog: false,
      menuItems: [
        {
          type: 'divider'
        },
        {
          type: 'text',
          title: 'Principal'
        },
        {
          title: 'Home',
          icon: 'mdi-home',
          path: '/home'
        },
        {
          title: 'Alunos',
          icon: 'mdi-account-multiple',
          path: '/users',
          type: 'personal'
        },
        {
          type: 'divider'
        },
        {
          type: 'text',
          title: 'Rotinas'
        },
        {
          title: 'Minhas rotinas',
          icon: 'mdi-list-box',
          path: '/routines'
        },
        {
          title: 'Criar rotina',
          icon: 'mdi-plus',
          path: '/routines/create',
          type: 'personal'
        },
        {
          type: 'divider'
        },
        {
          type: 'text',
          title: 'Treinos'
        },
        {
          title: 'Meus treinos',
          icon: 'mdi-dumbbell',
          path: '/workouts',
          type: 'personal'
        },
        {
          title: 'Criar treino',
          icon: 'mdi-plus',
          path: '/workouts/create',
          type: 'personal'
        },
        {
          type: 'divider'
        },
        {
          type: 'text',
          title: 'Exercícios'
        },
        {
          title: 'Exercícios',
          icon: 'mdi-weight-lifter',
          path: '/exercises'
        },
        {
          type: 'divider'
        },
        {
          type: 'text',
          title: 'Outros'
        },
        // {
        //   title: 'Estatísticas',
        //   icon: 'mdi-chart-bar',
        //   path: '/statistics',
        //   type: 'client'
        // },
        {
          title: 'Perfil',
          icon: 'mdi-account',
          path: '/profile'
        }
        // {
        //   title: 'Chats',
        //   icon: 'mdi-chat',
        //   path: '/chat'
        // }
      ]
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/me']
    },
    filteredUsers() {
      if (!this.search) {
        return null
      }
      return this.users.filter((user) => {
        return user.email.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  watch: {
    drawer(val) {
      if (!val) {
        this.search = ''
        this.$emit('close')
      }
    }
  },
  methods: {
    async logout() {
      await this.$store.dispatch('auth/logout')
      this.goTo('/login')
    },
    goTo(path) {
      this.$store.dispatch('route/goTo', path)
    }
  }
}
</script>
