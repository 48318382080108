import { getField, updateField } from 'vuex-map-fields'
import api from '@/services/api'

export const state = () => ({})

export const getters = {
  getField
}

export const actions = {
  async removeClient({ commit }, id) {
    const { data } = await api.delete(`personal/client/${id}`)
    return data
  }
}

export const mutations = {
  updateField
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
