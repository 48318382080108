<template lang="pug">
v-dialog(
  v-model="showFeedbackDialog"
  transition="dialog-bottom-transition"
  max-width="500px"
)
  v-card.pa-2
    v-container
      v-row(justify="center")
        v-col(cols=12) 
          .text-h6.font-weight-bold.text-center Dê seu feedback a plataforma!
          .text-subtitle-2.text-center Você pode dizer o que gostaria de ver no aplicativo, o que não gostou, o que pode ser melhorado, etc. Sua opinião é muito importante para nós!
        v-col(cols=12)
          v-form(ref="form" v-model="valid")
            v-textarea.__text-field(
              v-model="message"
              placeholder="Digite aqui seu feedback"
              color="primary"
              rows="5"
              auto-grow
              rounded
              filled
              dark
              hide-details
              :rules="[rules.required]"
            )
        v-col(cols=12)
          v-btn(
            color="primary"
            block
            elevation="0"
            :loading="loading"
            :disabled="!valid"
            @click="sendFeedback()"
          )
            .secondary--text Enviar
</template>

<script>
export default {
  model: {
    prop: 'showFeedbackDialog',
    event: 'closeDialog'
  },
  props: {
    showFeedbackDialog: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      message: '',
      valid: false,
      loading: false,
      rules: {
        required: (value) => !!value || 'Campo obrigatório'
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/me']
    }
  },
  watch: {
    showFeedbackDialog(val) {
      if (!val) {
        this.$emit('closeDialog')
      }
    }
  },
  methods: {
    async sendFeedback() {
      this.loading = true
      try {
        await this.$store.dispatch('feedbacks/create', {
          message: this.message,
          userOwner: this.$store.getters['user/me'].id
        })
        this.$store.dispatch(
          'snackbar/success',
          'Feedback enviado com sucesso!'
        )
        this.$emit('closeDialog')
        this.message = ''
      } catch (error) {
        console.error(error)
      }
      this.loading = false
    }
  }
}
</script>
