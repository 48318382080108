import axios from 'axios'
import store from '../store'

const dev = process?.env?.VUE_APP_ENVIRONMENT === 'development' ? true : false
const token = store?.getters['user/token']

const api = axios.create({
  baseURL: dev
    ? 'http://localhost:3000/api/v2'
    : 'https://fysique-api-v2-bi5ykfr3ua-uc.a.run.app/api/v2',
  headers: {
    'Content-Type': 'application/json',
    Authorization: token ? `Bearer ${token}` : undefined
  }
})

export default api
