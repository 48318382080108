import { getField, updateField } from 'vuex-map-fields'
import api from '@/services/api'

export const state = () => ({})

export const getters = {
  getField
}

export const actions = {
  async getStatisticsByExercise({ commit }, payload) {
    console.log('getStatisticsByExercise', payload)
    const { data } = await api.get('/statistics/client/exercise/', {
      params: payload
    })
    return data
  }
}

export const mutations = {
  updateField
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
