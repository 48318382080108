<template lang="pug">
v-app(app)
  //- Snackbar padrão para mensagens de sucesso e erro
  Snackbar
  //- Dialog de confirmação
  ConfirmationDialog
  v-main(style="background-color: #151515; color: white")
    v-row(no-gutters justify="center")
      v-col(
        cols=12
        md="12"
        xl="10"
      )
        transition(
          name="fade"
          mode="out-in"
          appear
        )
          LoadingScreen(v-if="loading")
          router-view(v-else)
  NavigationDrawer(
    v-if="showAppbar && $vuetify.breakpoint.mdAndUp"
    @openFeedbackDialog="showFeedbackDialog = true"
    @openHelpDialog="showHelpDialog = true"
    @openWhatsNewDialog="showWhatsNewDialog = true"
  )
  BottomNavigation(v-if="showAppbar && $vuetify.breakpoint.smAndDown")
  v-row(
    v-if="showWorkoutInProgress"
    transition="fade-transition"
    no-gutters
    style="z-index: 1; position: fixed; bottom: 0; right: 0; margin: 0px auto 70px auto; width: 100%; max-width: 500px; padding: 0 16px"
  )
    v-col.text-center(
      cols=12
      style="background-color: #2e2e2e; border-radius: 10px; padding: 8px 0"
    )
      v-row(
        no-gutters
        align="center"
        justify="center"
      )
        v-col.mb-1(cols=12)
          .text-body-2.font-weight-medium.grey--text Treinamento em progresso
        v-col.mr-5(cols="auto")
          v-btn(
            color="primary"
            small
            dark
            text
            @click="goToWorkoutInProgress"
          )
            .primary--text.text-none Continuar
            v-icon(color="primary" right) mdi-play

        v-col(cols="auto")
          v-btn(
            color="red"
            small
            dark
            text
            @click="discardWorkout"
          )
            .red--text.text-none Descartar
            v-icon(color="red" right) mdi-delete
  Feedback(
    v-model="showFeedbackDialog"
    @closeDialog="showFeedbackDialog = false"
  )
  WhatsNew(
    v-model="showWhatsNewDialog"
    @closeDialog="showWhatsNewDialog = false"
  )
  Help(v-model="showHelpDialog" @closeDialog="showHelpDialog = false")
</template>

<script>
import Feedback from '@/components/dialogs/Feedback.vue'
import WhatsNew from '@/components/dialogs/WhatsNew.vue'
import Help from '@/components/dialogs/Help.vue'
import LoadingScreen from '@/components/LoadingScreen.vue'
import NavigationDrawer from '@/pages/navbar/NavigationDrawer.vue'
import BottomNavigation from '@/pages/navbar/BottomNavigation.vue'
import Snackbar from '@/components/actions/Snackbar.vue'
import ConfirmationDialog from '@/components/utils/ConfirmationDialog.vue'
import { mapGetters, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  components: {
    Feedback,
    WhatsNew,
    Help,
    LoadingScreen,
    NavigationDrawer,
    BottomNavigation,
    Snackbar,
    ConfirmationDialog
  },
  data() {
    return {
      showFeedbackDialog: false,
      showWhatsNewDialog: false,
      showHelpDialog: false,
      drawer: false,
      routes: [
        {
          title: 'Home',
          icon: 'mdi-home',
          path: '/home'
        },
        {
          title: 'Perfil',
          icon: 'mdi-account',
          path: '/profile'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('snackbar', ['snackbar']),
    ...mapFields('workouts', ['workout', 'workoutInProgress']),
    ...mapState('loading', ['loading']),
    showAppbar() {
      return this.$route.meta.layout !== 'no-sidebar'
    },
    showWorkoutInProgress() {
      const routesNotAllowed = [
        '/',
        '/login',
        '/register',
        '/forgot-password',
        '/reset-password',
        '/convite',
        '/workout/start'
      ]
      if (
        this.workout &&
        this.workoutInProgress &&
        !routesNotAllowed.includes(this.$route.path)
      ) {
        return true
      } else {
        return false
      }
    }
  },
  created() {
    this.$store.dispatch('user/refresh', this.$store.getters['user/me']?.id)
  },
  methods: {
    goToWorkoutInProgress() {
      this.$store.dispatch('route/goTo', {
        path: '/workout/start',
        params: {
          id: this.workout
        },
        replace: true
      })
    },
    discardWorkout() {
      console.log('descartou')
      this.workoutInProgress = false
      this.$store.dispatch('workouts/setWorkout', null)
    }
  }
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.__text-field {
  color: white !important;
  border-radius: 6px !important;
  background-color: #252525 !important;
}

.custom-card:hover {
  background-color: #252525;
  cursor: pointer;
}
</style>
