import { getField, updateField } from 'vuex-map-fields'
import api from '@/services/api'
import { auth } from '@/services/supabase'

export const state = () => ({
  me: {},
  session: {}
})

export const getters = {
  getField,
  me(state) {
    return state.me || {}
  },
  token(state) {
    return state.session?.session?.access_token
  }
}

export const actions = {
  async get({ commit }, id) {
    const { data } = await api.get(`/users/${id}`)
    return data
  },
  async list({ commit }, { where = {} }) {
    const { data } = await api.get('/users', {
      params: {
        where: JSON.stringify({
          ...where
        })
      }
    })
    return data
  },
  async create({ commit }, { name = '', email, personalOwner, type }) {
    try {
      const { data } = await api.post('/users', {
        name,
        email,
        personalOwner,
        type
      })
      return data
    } catch (err) {
      console.error('ERRO NO CREATE', err)
    }
  },

  async update({ commit }, payload) {
    const { data } = await api.put(`/users/${payload.id}`, payload)
    return data
  },

  async refresh({ commit }, id) {
    if (!id) {
      return
    }

    const { data } = await api.get(`/users/${id}`)
    const { data: session } = await auth.getSession()
    commit('updateField', {
      path: 'session',
      value: session
    })
    commit('updateField', { path: 'me', value: data })
    return data
  },
  // async getMe({ commit }) {
  //   const auth = getAuth()
  //   const { data } = await api.get('/users/email', {
  //     params: {
  //       uid: auth.currentUser.uid
  //     }
  //   })
  //   commit('updateField', { path: 'me', value: data[0] })
  // },
  async updateUser({ commit }, payload) {
    console.log('updateUser', payload)
    const { data } = await api.put(`/users/${payload.id}`, payload)
    commit('updateField', { path: 'user/me', value: data }, { root: true })
  },
  async deleteUser({ commit }, id) {
    const { data } = await api.delete(`/users/${id}`)
    commit('updateField', { path: 'user/me', value: {} }, { root: true })
    return data
  },
  async myPersonal({ commit }, id) {
    const { data } = await api.get('/personal', {
      params: {
        id: id
      }
    })
    console.log('myPersonal', data)
    commit('updateField', { path: 'personal', value: data })
    return data
  }
}

export const mutations = {
  updateField
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
