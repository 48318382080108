import app from './app'
import auth from './auth'
import user from './user'
import personal from './personal'
import exercises from './exercises'
import routines from './routines'
import workouts from './workout'
import createWorkout from './createWorkout'
import lastActivities from './lastActivities'
import statistics from './statistics'
import clients from './clients'
import snackbar from './snackbar'
import loading from './loading'
import route from './route'
import chat from './chat'
import feedbacks from './feedbacks'
import tickets from './tickets'
import muscles from './muscles'
import equipments from './equipments'
import invites from './invites'
import news from './news'
import finishedWorkouts from './finishedWorkouts'
import routineDetails from './routineDetails'
import routinesWorkouts from './routinesWorkouts'
import solicitations from './solicitations'

export default {
  app,
  auth,
  user,
  personal,
  exercises,
  workouts,
  createWorkout,
  routines,
  lastActivities,
  statistics,
  clients,
  snackbar,
  loading,
  route,
  chat,
  feedbacks,
  tickets,
  muscles,
  equipments,
  invites,
  news,
  finishedWorkouts,
  routineDetails,
  routinesWorkouts,
  solicitations
}
