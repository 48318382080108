import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    options: { customProperties: true },
    dark: true,
    themes: {
      dark: {
        primary: '#C9F66F',
        secondary: '#252525'
      }
    }
  }
})
