import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyAKU_b4AUSFGv-uxm2r-44USJIA3JE6-FI',
  authDomain: 'workout-project-bb3d1.firebaseapp.com',
  projectId: 'workout-project-bb3d1',
  storageBucket: 'workout-project-bb3d1.appspot.com',
  messagingSenderId: '414674324134',
  appId: '1:414674324134:web:15179eeacf6a5d85aac267'
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const storage = getStorage(app)
const db = getFirestore(app)

export { auth, storage, db }
