import { getField, updateField } from 'vuex-map-fields'
import router from '@/routes/router'

export const state = () => ({})

export const getters = {
  getField
}

export const actions = {
  goTo(_, payload) {
    if (typeof payload !== 'string') {
      router.push({
        path: payload.path,
        query: payload.query
      })
    } else {
      const currentRoute = router.currentRoute
      if (currentRoute.path === payload) {
        return
      }

      router.push(payload)
    }
  }
}

export const mutations = {
  updateField
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
