import { getField, updateField } from 'vuex-map-fields'
import api from '@/services/api'

export const state = () => ({})

export const getters = {
  getField
}

export const actions = {
  async get(_, id) {
    const { data } = await api.get(`/routines/${id}`)
    return data
  },
  async list(_, { where = {} }) {
    const { data } = await api.get('/routines', {
      params: {
        where: JSON.stringify({
          ...where
        })
      }
    })
    return data
  },
  async create(_, payload) {
    const { data } = await api.post('/routines', payload)
    console.log('store routine create', data)
    return data
  },
  async update(_, payload) {
    const { data } = await api.put(`/routines/${payload.id}`, payload)
    return data
  },
  async delete(_, id) {
    await api.delete(`/routines/${id}`)
  },
  async duplicate(_, routine) {
    const { data: routineData } = await api.post(`/routines`, {
      ...routine,
      name: `${routine.name} - Cópia`
    })

    const workoutPromises = routine.workouts.map(async ({ workout }) => {
      const workoutPayload = {
        name: `${workout?.name} - Cópia`,
        description: workout?.description,
        exercises: workout?.exercises,
        day: workout?.day,
        mediaUrl: workout?.mediaUrl,
        userOwner: workout?.userOwner,
        rpe: workout?.rpe
      }

      const { data: workoutData } = await api.post(`/workouts`, workoutPayload)

      const routinesWorkoutsPayload = {
        routineId: routineData.id,
        workoutId: workoutData.id
      }

      api.post('/routines/workouts', routinesWorkoutsPayload)
    })

    await Promise.all(workoutPromises)

    return routineData
  },
  async myRoutines({ commit }, userOwner) {
    const { data } = await api.get('routines', {
      params: {
        where: JSON.stringify({
          userOwner
        })
      }
    })
    return data
  }
  // async getAssignedClients({ commit }, payload) {
  //   const { data } = await api.get(`routines/assigned/${payload.routineId}`)
  //   return data
  // },

  // async assignRoutineToClient({ commit }, payload) {
  //   const { data } = await api.post('users/routines', payload)
  //   return data
  // },

  // async unassignRoutine({ commit }, payload) {
  //   const { data } = await api.delete(
  //     `users/routines?routineId=${payload.routineId}&clientId=${payload.clientId}`
  //   )
  //   return data
  // },
}

export const mutations = {
  updateField
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
