export const defaultSerie = {
  name: 'Preparatória',
  acronym: 'F',
  color: '',
  rest: '01:30',
  kg: 0,
  reps: 0,
  done: false,
  description:
    'É uma série que visa desenvolver a força e a potência muscular, usando cargas maiores, porém se mantendo longe da falha'
}
