import { getField, updateField } from 'vuex-map-fields'
import api from '@/services/api'

export const state = () => ({
  clients: []
})

export const getters = {
  getField
}

export const actions = {
  async myClients({ commit }, id) {
    const { data } = await api.get('/users', {
      params: {
        where: JSON.stringify({
          personalOwner: id
        })
      }
    })
    commit('updateField', { path: 'clients', value: data })
    return data
  },
  async addRoutineToClient(_, payload) {
    const { data } = await api.post('/routines', payload)
    return data
  }
}

export const mutations = {
  updateField
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
